import React, { useState, useEffect } from "react"
import { useLazyQuery, useMutation } from '@apollo/client';
import VERIFY_AC_USER_MUTATION from "../queries/verifyUserMutation"

const APIPage = () => {

  console.log('First : ' +new Date().toLocaleString())

  /*  Axios
      .post("/done", answers)
      .then(res => this.setState({results:res.data,resultsStatus:true}))
      .catch(
        err => console.log(err)
      );
*/
/*
        Axios.get("https://livingwellnow.api-us1.com/admin/api.php?api_key=c7d98270518e8c9980ef3889cf6e92787f4745d3cf678d45bf594172e60b37684c5c4421&api_action=contact_view_hash&api_output=json&hash=bdb1e9d1c0f615682a5fd55cd7622403000",
        //Axios.get("https://lulaschiller.api-us1.com/admin/api.php?api_key=a1529ab7c790996aa3a2bb8b714299d6a31e7578da27d8ac781d880b811018441aff1535&api_action=contact_view_hash&api_output=json&hash=7658b2b56a2490ef65b765fdcfc9b204",
        {
               crossdomain: true
        })
          .then(result => {

            console.log(result);
           // const bookData = result.data
          //  console.log(bookData)
           // this.setState({ bookList: bookData.books })
           // this.rebuildIndex()
          })
          .catch(err => {
              console.log(err);

        })
  */      
/*
  useEffect(() => {
    // get data from GitHub api
    fetch(`/admin/api.php?api_key=c7d98270518e8c9980ef3889cf6e92787f4745d3cf678d45bf594172e60b37684c5c4421&api_action=contact_view_hash&api_output=serialize&hash=bdb1e9d1c0f615682a5fd55cd762240312345555`,
    {
      method:"GET",
     // mode: "no-cors",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded', // Your headers
      }
    }
    )
      .then(response => response) // parse JSON from request
      .then(resultData => {
        //setStarsCount(resultData.stargazers_count)
        console.log('resultData');
        console.log(resultData);
      }) // set data for the number of stars
  }, [])*/

  // Verify User
  const [verifyACUser] = useMutation(VERIFY_AC_USER_MUTATION, {
    onCompleted: ( verifiedData ) => {
      console.log(verifiedData)
    },
    onError: () => {
      console.log('error')
    }
  });
  useEffect(() => {
  verifyACUser({variables: {"clientMutationId": "verify", "token": "a25a1cde99281fabd2f5ef935e1f017e"}});
}, [])

  console.log('Last : ' +new Date().toLocaleString())
  return (
    <section>
      <p>Test API</p>
    </section>
  )
}
export default APIPage
